import React from "react"
import styled from "styled-components" // eslint-disable-line
import Img from "gatsby-image"
import { color, font } from "../../layout/global"

const SingleBlogHeader = ({ id, img, title }) => {
  return (
    <Wrapper id={id}>
      <div className="container">
        <Img
          fluid={img.childImageSharp.fluid}
          imgStyle={{
            objectFit: "cover",
            objectPosition: "center center",
          }}
        />
        <h1>{title}</h1>
      </div>
    </Wrapper>
  )
}

export default SingleBlogHeader

const Wrapper = styled.section`
  height: 50vw;
  max-height: 700px;

  @media only screen and (max-width: 1025px) {
    padding-top: 75px;
    background-color: #f0f1f1;
    height: 100%;
  }

  .container {
    position: relative;
    height: 100%;
    background: black;

    .gatsby-image-wrapper {
      height: 100%;
      opacity: 0.5;
    }

    h1 {
      position: absolute;
      bottom: 40%;
      left: 0;
      margin-left: 3%;
      color: ${color.white};
      text-transform: uppercase;
      ${font.robotoBold};
      letter-spacing: 3px;
      font-size: min(max(15px, 5vw), 30px);
      max-width: 50%;

      @media only screen and (max-width: 1025px) {
        height: fit-content;
        max-width: 100%;
        margin-left: 20px;
        margin-right: 20px;
        font-size: 20px;
        letter-spacing: 1px;
        top: 50%;
        bottom: 0%;
        transform: translateY(-50%);
      }

      @media only screen and (min-width: 451px) and (max-width: 1920px) {
        font-size: calc(17px + 18 * (100vw - 450px) / 1470);
      }

      @media only screen and (max-width: 450px) {
        font-size: 16px;
      }
    }
  }
`
