import React from "react"
import { Link } from "gatsby"
import styled from "styled-components" // eslint-disable-line
// import Img from "gatsby-image"
import { color, font } from "../../layout/global"
// import Slider from "react-slick"

const SingleBlogContent = ({ id, html, date, location }) => {
  // const sliderSettings = {
  //   dots: true,
  //   infinite: false,
  //   centerMode: true,
  //   slidesToShow: 1,
  //   variableWidth: true,
  //   swipeToSlide: true
  // }

  const path = location.pathname.slice(0, 3)
  const string =
    path === "/pt"
      ? "Voltar"
      : path === "/en"
      ? "Go back"
      : path === "/es"
      ? "Volver"
      : path === "/fr"
      ? "Retour"
      : null
  return (
    <Wrapper id={id}>
      <div className="container">
        <div className="content">
          <Link className="return-link" to={`/blog`}>
            {`< `}
            {string}
          </Link>
          <div className="date">{date}</div>
          <div className="text" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
        <div className="slider" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Wrapper>
  )
}

export default SingleBlogContent

const Wrapper = styled.section`
  .container {
    position: relative;
    height: 100%;

    .content {
      background: #f0f1f1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5vw 10% 15em;
      display: flex;
      align-items: center;

      @media only screen and (max-width: 900px) {
        height: fit-content;
      }

      .return-link {
        ${font.robotoBold};
        text-transform: uppercase;
        letter-spacing: 1px;
        opacity: 0.5;
        color: ${color.greyDarker};
        font-size: 14px;
        padding-bottom: 15px;
        text-align: left;
        margin-right: auto;
        transition: all 0.3s ease-out;

        &:hover {
          opacity: 0.7;
        }
      }
      .date {
        ${font.robotoBold};
        text-transform: uppercase;
        color: ${color.greyDark};
        font-size: 10px;
        padding-bottom: 15px;
        width: 100%;
        max-width: 1000px;
      }

      .text {
        max-width: 1000px;

        .text-content {
          ${font.robotoMedium};
          color: "#414643";
          letter-spacing: normal;
          font-size: min(max(15px, 5vw), 18px);
          margin: 0;
          line-height: 1.3;
          height: fit-content;

          @media only screen and (max-width: 1300px) {
            ${font.robotoRegular};
          }

          @media only screen and (max-width: 415px) {
            font-size: 15px;
          }
        }
      }

      .slider-imgs {
        display: none;
      }
    }

    .slider {
      background: #fff;
      padding: 0 10%;

      .text-content {
        display: none;
      }

      .slider-imgs {
        transform: translateY(-10vh);
        max-width: 700px;
        margin: 0 auto;
      }
    }
  }
`
